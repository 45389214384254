<template>
  <div>
    <div class="page-menu">
      <ul>
        <li>
          <router-link :to="{ name: 'siteadmin.public.home' }">Public</router-link>
        </li>
        <!-- <li>
          <router-link :to="{ name: 'siteadmin.learner.home' }">Learner</router-link>
        </li>
        <li>
          <router-link :to="{ name: 'siteadmin.trader.home' }">Trader</router-link>
        </li> -->
      </ul>
    </div>

    <router-view />
  </div>
</template>

<script>
export default {
  
}
</script>

<style lang="scss" scoped>
.page-menu {
  background: #fff;
  width: 100%;
  text-align: center;
  padding: 5px 0;
  border-bottom: solid 1px #000;

  ul {
    list-style: none;
    padding: 0;
    margin: 0;

    li {
      display: inline-block;

      a {
        padding: 0 20px;
      }
    }
  }
}
</style>